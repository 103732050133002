<template>
    <teleport to="head">
        <title>{{ txt[prefs.lang].contacts }} – PLANTA</title>
<!--        <meta name="description" :content=product[prefs.lang].meta_description>-->
<!--        <meta name="keywords" :content=product[prefs.lang].meta_keyword>-->
    </teleport>

<!--    <teleport to="#top_benefit">-->
<!--        <div class="benefit_area">-->
<!--            <h1>{{ txt[prefs.lang].contacts }}</h1>-->
<!--        </div>-->
<!--    </teleport>-->

    <section class="centered">

<!--        <el-button @click="test" plain round>Test</el-button>-->

        <h1>{{ txt[prefs.lang].contacts }}</h1>

        <ul>
            <li><a class="extls" href="tel:+380985550122" title="Kyivstar">(098) 555 01 22</a></li>
            <li><a class="extls" href="tel:+380660090122" title="Vodafone">(066) 009 01 22</a></li>
            <li><a class="extls" href="tel:+380930500122" title="Lifecell">(093) 050 01 22</a></li>
            <li style="padding: 1em;"><a class="clickable" @click="copy('eco@planta.com.ua')">eco@planta.com.ua</a></li>
            <li><a class="extls" target="_blank" href="sgnl://signal.me/#p/+380985550122" title="Signal">Signal</a></li>
            <li><a class="extls" target="_blank" href="viber://chat?number=380985550122" title="Viber">Viber</a></li>
            <li><a class="extls" style="text-decoration-line: line-through;" target="_blank" href="https://t.me/planta_eco">Telegram</a>&nbsp;<a class="extls" style="text-decoration-line: line-through;" target="_blank" href="https://kremlingram.org/ua"><el-button icon="el-icon-info" type="text" style="color: #888;" /></a></li>
        </ul>

<!--        <h2>{{ txt[prefs.lang].sm }}</h2>-->
        <ul>
            <li><a class="extls" target="_blank" href="https://www.instagram.com/planta.eco/">Instagram</a></li>
            <li><a class="extls" target="_blank" href="https://www.facebook.com/planta.eco">Facebook</a></li>
        </ul>

        <h2>{{ txt[prefs.lang].working_hours_h }}</h2>
        <div v-html="settings['working_hours_'+prefs.lang]" class="text"></div>

<!--        <div v-html="txt[prefs.lang].working_hours_i+txt[prefs.lang].working_hours_p" class="text"></div>-->

        <div class="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1680.0589678533377!2d35.04689649995156!3d48.46712121943817!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2f29472d7573d21f!2z0K3QutC-0LfQtdC80LvQtdC00LXQu9C40LUgLyBQTEFOVEE!5e0!3m2!1sru!2sua!4v1566041860364!5m2!1sru!2sua" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>
        </div>

    </section>
</template>

<script>
import useCart from "@/use/cart"
import { txt } from '@/use/txt'
import { onMounted } from "vue";
import { useQuery, useMutation, useResult } from "@vue/apollo-composable";
import { gql } from "@apollo/client";

export default {
    setup() {
        const { prefs, message, settings, log_action } = useCart()

        function copy(v) {
          log_action({ type: 3, value: 'email' })
          navigator.clipboard.writeText(v).then(function() {
                message(txt[prefs.lang].copied, v, 'success')
            })
        }

        onMounted(() => {
          document.querySelectorAll('.extls').forEach((i) => {
            i.addEventListener('click', e => {
              e.preventDefault()
              // console.log(e.target.href)
              log_action({ type: 3, value: e.target.href })
              window.open(e.target.href)
              // if (e.target.target.length) window.open(e.target.href, '_blank');
              // else window.open(e.target.href)
            })
          })
        })

        function test() {
          useQuery(gql`
              {test}
          `)
        }

        return { prefs, txt, copy, settings, test }
    }
}
</script>

<style scoped>
    li {
        font-size: 1.5em;
        padding-top: 0.3em;
    }
    .text {
        font-size: 1.3em;
        line-height: 1.6em;
    }
</style>